
.aboutPage {
  display: flex;
}

.email {
  display: block;
  color: #5DC0FE;
  font-family: "Cascadia Code";
  font-size: 14px;
  text-align: center;
  margin: 1em 0;
}

.socials {
  text-align: center;
}

.socials svg {
  fill: #FFFFFF90;
  transition: 0.2s;
}

.socials a:hover svg {
  fill: #FFFFFF;
}

.socials .socialLink {
  display: block;
  text-align: center;
  margin: 0.5em 0;
}

@media screen and (max-width: 768px) {
  .aboutPage {
    display: block;
  }

  h1 {
    text-align: center;
  }

  .avatar {
    margin: auto;
  }

  .socials .socialLink {
    display: inline-block;
    margin: 0 0.5em;
  }
}

.avatarContainer {
  flex: 0;
  display: block;
}

.avatar {
  background-image: url("../assets/images/profile_pic.jpg");
  background-size: contain;
  min-width: 200px;
  min-height: 200px;
  max-width: 200px;
  max-height: 200px;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0px 0px 8px black;
}

.content {
  flex: 1;
  padding-left: 1em;
}
