
.navigation {
  text-align: center;
}

.menu {
  position: relative;
  display: inline-block;
  list-style: none;
  padding-left: 0;
  margin: auto;
  border-radius: 0.5em;
}

.menuitem {
  display: inline-block;
  position: relative;
}

.menuitem a {
  color: #027DCA;
  font-family: "Montserrat Light";
  font-size: 20px;
  text-decoration: none;
  border-radius: 0.5em;
  display: inline-block;
  padding: 0.5em 1em;
  margin: 0 1em;
  transition: 0.2s;
}

.menuitem a:hover {
  color: #0DA1FD;
}

.menuitem a.active::after {
  width: 100%;
}

.menuitem a.active {
  color: #9AD8FE;
  pointer-events: none;
}

.menuitem a span {
  display: block;
  transition: 200ms;
}

.menuitem a.active span {
  transform: scale(1.3);
}

/* Mobile responsive rules */
@media screen and (max-width: 320px) {
  .menu {
    width: 100%;
  }
}

@media screen and (max-width: 670px) {
  .menuitem {
    display: block;
  }
}
