
.container {
  background: url("../../assets/images/scanlines_darkest.png");
  margin: 1em 0;
  padding: 1em 1em 2em 1em;
  border-radius: 0.5em;
  box-shadow: 0px 0px 8px #00000050;
  position: relative;
}

.container:first-child {
  margin-top: 0;
}

.container:last-child {
  margin-bottom: 0;
}

.flex {
  display: flex;
  align-items: center;
}

.expanded {
  display: block;
}

.title {
  display: block;
  font-family: "Montserrat";
  text-align: center;
  font-size: 1.5em;
  text-shadow: 0px 2px 4px black;
  margin-bottom: 0.5em;
}

.expanded .image {
  display: block;
  margin: 0 auto;
  text-align: center;
  max-width: 500px;
  max-height: 400px;
}

.image {
  flex: 1;
  display: inline-block;
  min-width: 178px;
  min-height: 133px;
  max-width: 200px;
  max-height: 200px;
  transition: max-height 0.2s, max-width 0s;
  overflow: hidden;
}

.image img {
  border-radius: 0.5em;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.content {
  flex: 2;
  margin-left: 1em;
  align-self: flex-start;
}

.expanded .content {
  margin-left: 0;
}

.expand {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  border-radius: 0 0 0.5em 0.5em;
  color: #FFFFFF90;
  background-color: #5DC0FE25;
  text-align: center;
  font-size: 14px;
  padding: 0.25em 0;
  transition: 0.2s;
}

.expand:hover {
  color: #FFFFFF;
  background-color: #5DC0FE35;
  cursor: pointer;
}

.details {
  padding-left: 0;
  list-style: none;
}

@media screen and (max-width: 700px) {
  .flex {
    display: block;
  }

  .image {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .content {
    margin-left: 0;
  }
}

@media screen and (max-width: 600px) {
  .expand {
    display: none;
  }
}
