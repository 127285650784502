
.fade-appear,
.fade-enter {
  opacity: 0;
  transform: scale(1.1);
  filter: blur(8px);
}

.fade-appear-active,
.fade-enter-active {
  transition: 300ms linear;
  opacity: 1;
  transform: none;
  filter: none;
}

.fade-exit {
  transition: 300ms linear;
  opacity: 1;
  transform: none;
  filter: none;
}

.fade-exit-active {
  opacity: 0;
  filter: blur(8px);
  transform: scale(0.9);
}
