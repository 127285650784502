
.skillBlock {
  display: flex;
  background: url("../../assets/images/scanlines_darkest.png");
  margin: 1em 0;
  padding: 1em;
  border-radius: 0.5em;
  align-items: center;
  box-shadow: 0px 0px 8px #00000050;
}

.skillBlock:first-child {
  margin-top: 0;
}

.skillBlock:last-child {
  margin-bottom: 0;
}

.logoContainer {
  flex: 0;
}

.logo {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 64px;
  height: 64px;
}

.logo.nodejs {
  background-image: url("../../assets/images/skills/nodejs.png");
}

.logo.react {
  background-image: url("../../assets/images/skills/react.png");
}

.logo.bigc {
  background-image: url("../../assets/images/skills/bigc.png");
}

.logo.typescript {
  background-image: url("../../assets/images/skills/typescript.png");
}

.logo.ruby {
  background-image: url("../../assets/images/skills/ruby.png");
}

.logo.php {
  background-image: url("../../assets/images/skills/php.png");
}

.logo.handlebars {
  background-image: url("../../assets/images/skills/handlebars.png");
}

.logo.electron {
  background-image: url("../../assets/images/skills/electron.png");
}

.skillContent {
  flex: 1;
  margin-left: 1em;
}

.skillTitle {
  font-family: "Montserrat Bold";
  margin-bottom: 0.5em;
}

.skillBar {
  background-color: #80808040;
  width: 100%;
  height: 1em;
  border: 1px solid black;
  border-radius: 0.25em;
}

.skillLevel {
  height: 100%;
  background-color: #5DC0FE;
  border-radius: 0.25em;
}

.skillDesc {
  display: flex;
  border-top: 1px solid gray;
}

.skillLabel {
  flex: 1;
  border-left: 1px dashed gray;
  font-size: 14px;
  padding-left: 0.5em;
}

.skillLabel:last-child {
  flex: 0;
  border-left: none;
  border-right: 1px dashed gray;
  padding-left: 0;
  padding-right: 0.5em;
  text-align: right;
}

@media screen and (max-width: 600px) {
  .skillDesc {
    display: none;
  }
}

@media screen and (max-width: 350px) {
  .skillTitle {
    font-size: 14px;
  }
}
