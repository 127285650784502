
header {
  text-align: center;
  margin: 4rem auto;
  /* background: url("../../assets/images/scanlines_dkgray.png"); */
  padding: 1em 0;
  border-radius: 0.5em;
  /* box-shadow: 0px 0px 8px #000000a0; */
}

.greetingContainer {
  display: inline-block;
  position: relative;
}

.greeting {
  font-size: 1.5em;
  font-family: "Cascadia Code";
  position: absolute;
  left: 0;
  top: -1em;
  text-shadow: 0px 2px 1px black;
}

.name {
  font-family: "Montserrat Light";
  letter-spacing: 0.5rem;
  font-size: 3.5em;
  margin: 0.5rem 0;
  text-shadow: 0px 4px 2px black;
}

.desc {
  font-family: "Montserrat Thin";
  font-size: 1.25em;
  margin: 0.5rem 0;
}

/* Smaller than 800px */
@media screen and (max-width: 800px) {
  .greeting {
    font-size: 3.4vw;
  }

  .name {
    font-size: 8vw;
  }

  .desc {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 600px) {
  .greeting {
    font-size: 20px;
  }

  .desc {
    font-size: 15px;
  }
}

@media screen and (max-width: 300px) {
  .name {
    font-size: 24px;
  }
}
