
.container {
  width: 100%;
  height: 125px;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  transition: 0.2s;
}

.image {
  display: inline-block;
  width: 200px;
  height: 125px;
  transition: 0.2s;
}

.image img {
  border-radius: 0.5em;
  max-width: 100%;
  max-height: 100%;
  transition: 0.2s;
}

.thumbnail {
  display: inline-block;
  width: 64px;
  height: auto;
  margin: 0.5em 0.5em;
}

.thumbnail img { 
  border-radius: 0.25em;
  transition: 0.2s;
}

.thumbnail[data-selected='true'] img {
  opacity: 1;
}

.thumbnail[data-selected='false'] img {
  cursor: pointer;
  opacity: 0.5;
}

@media screen and (max-width: 620px) {
  .image {
    display: none;
  }

  .thumbnail {
    width: 128px;
  }
}
