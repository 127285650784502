/* Font declarations */
/* Make sure to delete unused font files later */
@font-face {
  font-family: "Quicksand";
  src: url("./assets/fonts/Quicksand/static/Quicksand-Regular.ttf");
}

@font-face {
  font-family: "Quicksand Bold";
  src: url("./assets/fonts/Quicksand/static/Quicksand-Bold.ttf");
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf");
}

@font-face {
  font-family: "Source Sans Pro Light";
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-Light.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("./assets/fonts/Montserrat/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Montserrat Light";
  src: url("./assets/fonts/Montserrat/Montserrat-Light.ttf");
}

@font-face {
  font-family: "Montserrat Thin";
  src: url("./assets/fonts/Montserrat/Montserrat-Thin.ttf");
}

@font-face {
  font-family: "Cascadia Code";
  src: url("./assets/fonts/CascadiaCode/CascadiaMono.ttf");
}

/* Import transitions CSS separately to stay organized */
@import url("./transitions.css");

#app-container {
  min-width: 300px;
}

/* Main styles */
body {
  background: url("./assets/images/scanlines_dkgray.png");
  background-repeat: repeat;
  font-family: "Quicksand";
  font-size: 18px;
  color: white;
  margin: 0;
  overflow-x: hidden;
}

.main-container {
  position: relative;
  padding: 0 150px;
}

/* Mobile responsiveness */
@media screen and (max-width: 1080px) {
  .main-container {
    padding: 0 100px;
  }
}

@media screen and (max-width: 768px) {
  .main-container {
    padding: 0 50px;
  }
}

@media screen and (max-width: 480px) {
  .main-container {
    padding: 0 25px;
  }
}

.content-container {
  position: relative;
  margin: 0 auto;
  max-height: calc(100vh - 44px); /* minus nav menu height */
}

.route-wrapper {
  width: 100%;
  height: 200px;
  background: url("./assets/images/scanlines_lighter_dkgray.png");
  border-radius: 0.5em;
  box-shadow: 0px 4px 8px #00000080;
  transition: 0.1s;
}

.route-wrapper::before {
  display: block;
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  top: 0px;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, #5DC0FE90 50%, rgba(255,255,255,0) 100%);
}

.page-wrapper {
  position: absolute;
  width: 100%;
  margin: 1em 0;
}

.page {
  padding: 0 1em;
}

h1 {
  font-family: "Montserrat";
}

.section-title {
  display: block;
  margin: 0.25em 0.5em;
  text-shadow: 0px 2px 4px black;
  border-bottom: 1px solid #606060;
  padding-bottom: 0.5em;
}

strong {
  color: #5DC0FE;
}

a {
  text-decoration: none;
  color: #FFFFFFA9;
  transition: 0.2s;
}

a:hover {
  color: #A2DBFF;
}
